<template>
  <div class="infrastructura-map-inner">
    <div
      id="yandexMap"
      ref="refYandexMapInfrastr"
      class="infrastructura-map"
    ></div>
  </div>
</template>

<script lang="ts" setup>
// @ts-nocheck
import type { MapSettings } from 'vue-yandex-maps/src/types';
import { loadYmap } from 'vue-yandex-maps';
import type { InfrastructureBlockItemType, InfrastructureBlockPoint } from '~/types/InfrastructureBlockTypes';
import { onMounted, ref } from '#imports';

const props = defineProps<{
	pointsList: InfrastructureBlockItemType[];

}>();
const { yandexMapApiKey } = useRuntimeConfig().public

const settings: MapSettings = {
  apiKey: yandexMapApiKey,
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
};

const refYandexMapInfrastr = ref<HTMLElement | string>('');

let yandexMap: ymaps.Map;

const yandexMapInit = async () => {
  if (!refYandexMapInfrastr.value) {
    return;
  }

  await loadYmap(settings);

  await ymaps.ready(() => {
    yandexMap = new ymaps.Map(refYandexMapInfrastr.value, {
      center: [56.826100, 60.596656],
      zoom: 17,
      behaviors: ['hover', 'drag', 'dblClickZoom', 'multiTouch']
    }, {
      maxZoom: 20
    });

    yandexMapSetMarkers();
  });
};
const yandexMapSetHint = (project: InfrastructureBlockPoint, point: InfrastructureBlockItemTyp) => ymaps.templateLayoutFactory.createClass(`
		<div class='hintWrapper'>
     <span class='name'>${project?.clue || point.label}</span>
  </div>`, {
});

const yandexMapSetClusters = () => {
  const clusterCustom = ymaps.templateLayoutFactory.createClass('' +
		'<div class="clusterIcon">' +
		'<span>{{ properties.geoObjects.length }}</span>' +
		'</div>');

  return new ymaps.Clusterer({
    clusterDisableClickZoom: false,
    clusterNumbers: [50],
    clusterIconLayout: clusterCustom,
    clusterIconShape: {
      type: 'Rectangle',
      coordinates: [[0, 0], [46, 46]]
    }
  });
};

const yandexMapSetMarkers = () => {
  const cluster = yandexMapSetClusters();

  const isFullSelected = Boolean([...props.pointsList].filter((t) => t.isHidden).length);

  props.pointsList.forEach((point) => {
    if (isFullSelected && !point.isHidden && point.type !== 'home') {
      return;
    }

    point.points.forEach((project) => {
      const coords = [project?.lat, project?.lon];
      const className = 'map-point-content';

      const template = `
      <div class="${className}">
      		<div class="icon">
      				<img src=${point.icon} alt="img">
								</div>

      </div>
    `;

      const MyIconContentLayout = ymaps.templateLayoutFactory.createClass(template);
      const myPlacemarkWithContent = new ymaps.Placemark(coords, {
        balloonContentHeader: project.name

      }, {
        iconLayout: 'default#imageWithContent',
        iconImageHref: '',
        iconImageSize: [30, 37],
        iconImageOffset: [-15, -35],
        iconContentLayout: MyIconContentLayout,
        hideIconOnBalloonOpen: false,
        hintLayout: yandexMapSetHint(project, point)
      });

      cluster.add(myPlacemarkWithContent);
    });

    yandexMap.geoObjects.add(cluster);

    yandexMap.setBounds(cluster.getBounds(), {
      delay: 2000,
      checkZoomRange: true

    });
  });
};

const removeMarkers = () => {
  if (!yandexMap) {
    return;
  }
  yandexMap?.geoObjects?.removeAll();
};

watch(() => props.pointsList, () => {
  if (!yandexMap) {
    return;
  }

  removeMarkers();
  yandexMapSetMarkers();
});

onMounted(async () => {
  await yandexMapInit();
});
</script>

<style lang="scss">
@import "@/assets/scss/media";

.infrastructura-map {
	height: 100%;
	position: relative;

	& .yandex-container {
		height: 100%;
	}

	& .yandex-balloon {
		height: 200px;
		width: 200px;
	}
}

.hintWrapper {
	display: inline-block;
	padding: 5px 20px;
	box-sizing: border-box;
	position: relative;
	left: -10px;
	top: -55px;
	min-height: 35px;
	min-width: 195px;
	color: #333;
	text-align: center;
	vertical-align: middle;
	background-color: var(--secondary);
	filter: drop-shadow(0 4px 4px rgb(0 0 0 / 15%));
	border-radius: 8px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		border-top: 20px solid transparent;
		border-bottom: 20px solid transparent;
		border-radius: 8px 8px 8px 0;
		border-left: 20px solid var(--secondary);
	}
}

.infrastructura-map-inner {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.map-point-content {
	position: relative;
	min-width: 38px;

	.icon {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 37px;

		&::before {
			content: '';
			position: absolute;
			width: 30px;
			height: 37px;
			left: 0;
			bottom: 0;
			background-image: url("~/assets/img/svg/infrastructura/marker.svg");
			z-index: -1;
		}

		& > img {
			width: 16px;
			height: 16px;
			position: relative;
			top: -5px;
		}
	}

	&.--active {
		min-width: 56px;

		.icon {
			width: 54px;
			height: 78px;
			background-image: url("~/assets/img/svg/map/map-pin-point-big.svg") !important;
		}
	}
}

.ymaps-2-1-79-balloon,
.ymaps-2-1-79-balloon__layout {
	border-radius: 15px;
}

@include media('lg') {
}

@include media('md') {
}

@include media('md') {
}

@include media('sm') {
	.infrastructura-map-inner {
		height: 320px;
	}
}
</style>
